$(function() {

    lastScrollTop = 0;

    $('.swipebox-video').swipebox({
        videoMaxWidth: 1200,
        autoplayVideos: true
    });

    $('.hamburger').click(function() {

        var menu = $('nav ul');
        var burger = $('.hamburger');

        if (menu.css('visibility') == 'visible')
        {
            // menu is visible, so lets hide it
            menu.css('visibility','hidden');
            burger.prop('src','/img/hamburger.png');
        }
        else
        {
            menu.css('visibility','visible');
            burger.prop('src','/img/hamburger-x.png');
        }

    });

    $('nav ul li').click(function(e)
    {
       e.preventDefault();
       if ($(this).children('a').prop('target') == "_blank")
       {
           window.open($(this).children('a').attr('href'));
       }
       else
       {
           location.href = $(this).children('a').attr('href');
       }
    });

    resizeMainMenu();

    $(window).resize(function() {
        resizeMainMenu();
    });

    // Hide the extra content initially, using JS so that if JS is disabled, no problemo:
    $('.read-more-content').addClass('hidden')

    // Set up a link to expand the hidden content:
        .before('<a class="read-more-show" href="#"><img src="/img/read-more-red.png" alt="Click to view more bio information" title="View More..."></a>')

        // Set up a link to hide the expanded content.
        .append(' <a class="read-more-hide" href="#"><img src="/img/read-more-grey.png" alt="Click to view less bio information" title="View Less..."></a>');

    // Set up the toggle effect:
    $('.read-more-show').on('click', function(e) {
        $(this).next('.read-more-content').removeClass('hidden');
        $(this).addClass('hidden');
        e.preventDefault();
    });

    $('.read-more-hide').on('click', function(e) {
        $(this).parent('.read-more-content').addClass('hidden').parent().children('.read-more-show').removeClass('hidden');
        e.preventDefault();
    });

    $('.share-me').click(function() {
        showShareOverlay($(this));
    });

    $('.overlay .close').click(function() {
        $('#the-big-share').fadeOut();
    });

    var t = 575,
        i = 440,
        r = ($(window).width() - t) / 2,
        u = ($(window).height() - i) / 2,
        n = 'status=1,width=' + t + ',height=' + i + ',top=' + u + ',left=' + r;
    $('.facebook').on('click touchend', function(t) {
        t.preventDefault();
        var i = $('#link-facebook').attr('href');
        trackEvent('Facebook', 'share', i);
        i || (i = location.href), window.open(i, 'sharer', n)
    });
    $('.twitter').on('click touchend', function(t) {
        console.log('twitter clicked');
        t.preventDefault();
        var i = $('#link-twitter').attr('href');
        trackEvent('Twitter', 'share', i);
        i || (i = location.href), window.open(i, 'intenter', n)
    });
    $('.gplus').on('click touchend', function(t) {
        t.preventDefault();
        var i = $('#link-gplus').attr('href');
        trackEvent('GooglePlus', 'share', i);
        i || (i = location.href), window.open(i, 'plusser', n)
    });
    $('.linkedin').on('click touchend', function(t) {
        t.preventDefault();
        var i = $('#link-linkedin').attr('href');
        trackEvent('LinkedIn', 'share', i);
        i || (i = location.href), window.open(i, 'linker', n)
    });

});

function trackEvent(type, action, label) {
    console.log(type, action, label);
    ga('send', 'event', type, action, label);
}

function headerOff() {
    $('header').fadeOut();
}

function headerOn() {
    $('header').fadeIn();
}

var throttled = _.throttle(scrollHeader, 100);
$(window).scroll(throttled);

function scrollHeader() {

    var t = $(window).scrollTop();

    if (t > lastScrollTop)
    {
        if (t > 80)
        {
            headerOff();
        }
    }
    else
    {
        headerOn();
    }

    lastScrollTop = t;

}

function resizeMainMenu() {

    var h = $(window).height();

    var n = h - 80;

    $('nav ul').css('height',n + 'px');
}

function showShareOverlay(el)
{
    console.log(el);
    var u = 'http://fortmiamicrossfit.com/' + el.data('url');
    var t = el.data('text');

    var s = $('#the-big-share');
    s.find('h1').text(t);
    s.find('#link-email').prop('href', 'mailto:?subject='+t+'&body='+encodeURI(u));
    s.find('#link-gplus').prop('href', 'https://plus.google.com/share?url='+u);
    s.find('#link-facebook').prop('href', encodeURI('https://www.facebook.com/dialog/feed?app_id=1248620278516252&link='+u+'&display=popup&picture=http://fortmiamicrossfit.com/img/background-membership.jpg&name=Fort Miami Crossfit&caption='+t+'&redirect_uri=http://fortmiamicrossfit.com'));
    s.find('#link-twitter').prop('href', encodeURI('https://twitter.com/intent/tweet?text='+t+'&counturl='+u+'&url='+u+'&via=FortMiamiCF'));
    s.find('#link-linkedin').prop('href', encodeURI('http://www.linkedin.com/shareArticle?mini=true&url='+u+'&title='+t+'&summary='));
    s.fadeIn();
}